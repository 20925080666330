<template>
  <div class="scrollable">
    <small class="el-icon-warning" style="
        color: #f7921c;
        margin-left: 1em;
        text-align: center;
        width: 100%;
        font-size: medium;
      " v-if="logintype != 'sms'" v-html="$t('dashboard.view_campaign.unanswered')">
    </small>
    <div class="row">
      <div class="col-md-12" v-if="loading">
        <div class="row pt-5">
          <div class="col-md-12" v-loading="loading"></div>
        </div>
      </div>
      <div class="col-md-12" v-if="!loading">
        <div class="row">
          <div class="col-sm-12 col-md-7 col-lg-8 col-xl-9">
            <div class="groups mnotify-card __main__group" style="padding: 0px !important">
              <div class="mnotify-card-header d-flex">
                <div class="contains-link-back">
                  <button class="__back__btn" @click="redirect">
                    <img width="20px" height="20px" src="../../../assets/left-arrow (1).svg" alt="" />
                  </button>
                  <h3 class="mnotify-card-header-text is-view-campaign">
                    {{ $t("dashboard.view_campaign.view_campaign") }}
                  </h3>
                </div>

                <div class="refresh-button">
                  <div class="clear-filters" style="margin-right: 1rem">
                    <el-button
                      v-if="statusSelectedIndex"
                      @click="clearFilters"
                      type="text"
                      icon="el-icon-circle-close"
                      style="color: #f7921c">
                      {{ $t("dashboard.view_campaign.clear_filters") }}
                    </el-button>
                  </div>
                  <el-button
                    v-if="!processingFlag"
                    class="refresh-button-main"
                    type="primary"
                    @click="refreshPageDisable"
                    :disabled="buttonDisabled"
                    >{{ $t("dashboard.view_campaign.refresh") }}</el-button
                  >
                </div>
                <div class="d-flex justify-content-end">
                  <div class="form_contain">
                    <el-input v-if="!processingFlag" :placeholder="$t('dashboard.view_campaign.search_contact')
                      " v-model="searchTableData" @input="searchUser" @clear="prepareCampaignDetails" clearable>
                      <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  </div>
                </div>
              </div>
              <div class="card-inner">
                <!-- <div class="__reporting__table" v-if="campaignDetails.length">
                                        <table class="__mnotify__table">
                                            <thead class="__mnotify__table__header">
                                                <tr>
                                                    <th style="width:25%;" class="__mnotify__table__header_first">Number</th>
                                                    <th style="width:25%;">Status</th>
                                                    <th style="width:25%;">Date & time</th>
                                                    <th style="width:35%;">Message</th>
                                                </tr>
                                            </thead>
                                            <tbody class="__mnotify__table__body">
                                                <tr class="__table__with__border" v-for="(detail, key) in campaignDetails" :key="key">
                                                    <td class="mnotify__table__first__item">{{detail.number}}</td>
                                                    <td>{{detail.status}}</td>
                                                    <td>{{moment(detail.date_time).format('llll')}}</td>
                                                    <td>{{detail.message}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> -->
                <div class="contact_table">
                  <el-table :header-cell-style="{
                    background: 'rgba(212, 216, 226, 0.1)',
                  }" :data="displayData" ref="singleTable" width="200" height="52vh" v-loading="tableLoading"
                    :empty-text="isProcessing">
                    <div slot="empty">
                      <p style="margin-bottom: 0">{{ isProcessing }}</p>

                      <el-button v-if="processingFlag" class="refresh-button-main" type="primary"
                        @click="refreshPageDisable" :disabled="buttonDisabled">{{ $t("dashboard.view_campaign.refresh")
                        }}</el-button>
                    </div>
                    <el-table-column :label="$t(
                      'dashboard.send_message.message_templates.form.content.phone'
                    )
                      " prop="number" width="140">
                    </el-table-column>
                    <el-table-column v-if="logintype == 'sms'" prop="status" :label="$t(
                      'dashboard.send_message.international_messages.status'
                    )
                      " width="150">
                      <template slot-scope="scope" class="contains-status">
                        <div class="notranslate">
                          <p v-if="scope.row.status == 'DELIVERED'" style="color: #36d962; margin: 0 auto">
                            {{ $t("dashboard.view_campaign.delivered") }}
                          </p>
                          <p v-else-if="scope.row.status == 'NOT_DELIVERED'" style="color: #000; margin: 0 auto">
                            {{ $t("dashboard.view_campaign.not_delivered") }}
                          </p>
                          <p v-else-if="scope.row.status == 'SUBMITTED'" style="color: #30a1f0; margin: 0 auto">
                            {{ $t("dashboard.view_campaign.submitted") }}
                          </p>
                          <p v-else-if="scope.row.status == 'FAILED'" style="color: #f0c630; margin: 0 auto">
                            {{ $t("dashboard.view_campaign.accepted") }}
                          </p>
                          <p v-else-if="scope.row.status == 'EXPIRED'" style="color: #f7921c; margin: 0 auto">
                            {{ $t("dashboard.view_campaign.expired") }}
                          </p>
                          <p v-else-if="scope.row.status == 'REJECTED'" style="color: #bc76ba; margin: 0 auto">
                            {{ $t("dashboard.view_campaign.rejected") }}
                          </p>
                        </div>
                      </template>
                    </el-table-column>
                    <!-- Status VOice -->
                    <el-table-column v-if="logintype !== 'sms'" prop="status" :label="$t(
                      'dashboard.send_message.international_messages.status'
                    )
                      " width="150">
                      <template slot-scope="scope" class="contains-status">
                        <div class="notranslate">
                          <p v-if="scope.row.dial_status == 'ANSWERED'" style="color: #36d962; margin: 0 auto">
                            {{ $t("dashboard.view_campaign.answered") }}
                          </p>
                          <p v-else-if="scope.row.dial_status == 'NO ANSWER'" style="color: #000; margin: 0 auto">
                            {{ $t("dashboard.view_campaign.no_answer") }}
                          </p>
                          <p v-else-if="scope.row.dial_status == 'PENDING'" style="color: #30a1f0; margin: 0 auto">
                            {{ $t("dashboard.view_campaign.pending") }}
                          </p>
                          <p v-else-if="scope.row.dial_status == 'BUSY'" style="color: #ff0000; margin: 0 auto">
                            {{ $t("dashboard.view_campaign.busy") }}
                          </p>
                          <p v-else-if="scope.row.dial_status == 'EXPIRED'" style="color: #f7921c; margin: 0 auto">
                            {{ $t("dashboard.view_campaign.expired") }}
                          </p>
                          <p v-else-if="
                            scope.row.dial_status == 'not_dialed_yet'
                          " style="color: #30a1f0; margin: 0 auto">
                            {{ $t("dashboard.view_campaign.not_dialed_yet") }}
                          </p>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column v-if="logintype !== 'sms'" prop="bill_sec"
                      :label="$t('dashboard.view_campaign.time_listened')" width="130">
                      <template slot-scope="scope">
                        {{ scope.row.bill_sec }}
                        {{ scope.row.bill_sec > 1 ? "secs" : "sec" }}
                      </template>
                    </el-table-column>
                    <!-- Audio lenght -->
                    <el-table-column v-if="logintype !== 'sms'" prop="audio_length"
                      :label="$t('dashboard.view_campaign.audio_length')" width="120">
                      <template slot-scope="scope">
                        {{ scope.row.audio_length }}
                        {{ scope.row.audio_length > 1 ? "secs" : "sec" }}
                      </template>
                    </el-table-column>
                    <el-table-column v-if="logintype !== 'sms'" prop="answer_time"
                      :label="$t('dashboard.view_campaign.answer_time')" width="150">
                    </el-table-column>
                    <el-table-column v-if="logintype !== 'sms'" prop="hang_up_time"
                      :label="$t('dashboard.view_campaign.hangup_time')" width="140">
                    </el-table-column>
                    <el-table-column v-if="logintype === 'sms'" prop="sender_id"
                      :label="$t('dashboard.overview.sender_id')" width="140" class-name="notranslate">
                    </el-table-column>
                    <el-table-column v-if="logintype !== 'sms'" prop="refund"
                      :label="$t('dashboard.view_campaign.refund_status')" width="140">
                      <template slot-scope="scope">
                        {{ checkRefund(scope.row) }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('dashboard.view_campaign.date_time')" width="250">
                      <template slot-scope="scope">
                        {{ moment(scope.row.date_time).format("llll") }}
                      </template>
                    </el-table-column>
                    <el-table-column v-if="logintype == 'sms'" :label="$t('dashboard.view_campaign.message')"
                      prop="message">
                      <template slot-scope="scope">
                        <span class="notranslate">{{
                          parseMessageEncode(scope.row.message)
                        }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="" style="text-align: center; margin-top: 2em">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :current-page="page"
                      @current-change="handleCurrentChange"
                      :page-size="pageSize"
                      :total="total">
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-5 col-lg-4 col-xl-3 scrollable-report">
            <div class="groups mnotify-card" style="padding: 0px !important">
              <div class="mnotify-card-header d-flex justify-content-between align-items-center">
                <h3 class="mnotify-card-header-text __headar">
                  {{ $t("dashboard.view_campaign.sms_campaign") }}
                </h3>
                <button v-loading="exportLoading" @click.prevent="exportReport" class="export">
                  {{ $t("dashboard.campaign_history.button_report.export") }}
                </button>
              </div>
              <div class="card-inner">
                <div class="mnotify-card-body" style="margin: 0px; padding: 0px"
                  v-if="campaignDetails.length || filterOn">
                  <RecentSMSChart @dataPointSelection="dataPointSelection" :campaignLabels="campaignLabels"
                    :CampaignCount="campaignSeries" />
                </div>
                <div class="senderid-card-inner" v-else>
                  <div class="__no-data mt-5" id="__nodata">
                    <h3 class="__no-data-header text-center">
                      {{ $t("dashboard.overview.no_data") }}
                    </h3>
                    <p class="__no-data-text text-center" v-if="!isProcessing">
                      {{ $t("dashboard.overview.send_messages") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import RecentSMSChart from "./campaignDetailsReporting.vue";
  import moment from "moment";
  import downloadcsv from "../../../helpers/downloadcsv";
  import loginType from "../../../helpers/loginType";
  import _ from "lodash";

  export default {
    name: "campaign-details",
    components: {
      RecentSMSChart,
    },
    data() {
      return {
        campaignDetails: [],
        campaignSeries: [],
        loading: false,
        exportLoading: false,
        page: 1,
        pageSize: 50,
        total: 0,
        filterOn: false,
        statusSelectedIndex: "",
        campaignLabels: [
          this.$t("dashboard.campaign_history.button_report.delivered"),
          this.$t("dashboard.view_campaign.accepted_lower"),
          this.$t("dashboard.campaign_history.button_report.submitted"),
          this.$t("dashboard.view_campaign.not_lower"),
        ],
        buttonDisabled: true,
        tableLoading: false,
        searchTableData: "",
        processingFlag: false,
      };
    },
    methods: {
      clearFilters() {
        this.statusSelectedIndex = "";
        this.page = 1;
        this.prepareCampaignDetails();
      },
      parseMessageEncode(message) {
        try {
          // Step 1: Handle HTML entities
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = message;
          let decodedMessage = tempDiv.textContent;

          // Step 2: Handle Unicode escape sequences
          decodedMessage = decodedMessage.replace(
            /\\u([0-9a-fA-F]{4})/g,
            (match, group) => {
              return String.fromCharCode(parseInt(group, 16));
            }
          );

          // Step 3: Handle specific character sequences
          const charMap = {
            "\u00C9\u203A": "ɛ", // Twi epsilon
            "\u00C9\u201D": "ɔ", // Twi open o
            "&amp;": "&",
            "&lt;": "<",
            "&gt;": ">",
            "&quot;": '"',
            "&#39;": "'",
          };

          return decodedMessage.replace(
            /\u00C9\u203A|\u00C9\u201D|&amp;|&lt;|&gt;|&quot;|&#39;/g,
            (match) => charMap[match] || match
          );
        } catch (error) {
          console.error("Message decode error:", error);
          return message; // Return original if decoding fails
        }
      },
      redirect() {
        if (loginType() === "sms") {
          this.$router.push("/sms/campaign/history").catch((err) => {
            if (err.name !== "NavigationDuplicated") throw err;
          });
        } else {
          this.$router.push("/voice/campaign/history").catch((err) => {
            if (err.name !== "NavigationDuplicated") throw err;
          });
        }
      },
      handleCurrentChange(val) {
        this.page = val;
        loginType() === "sms"
          ? this.fetchSpecificPage(val)
          : this.fetchSpecificVoice(val);
      },
      fetchSpecificPage(page) {
        this.tableLoading = true;
        store
          .dispatch("reporting/fetchSMSHistoryDetails", {
            id: this.$route.params.id,
            page: page,
          })
          .then((data) => {
            this.campaignDetails = data.data;
            this.total = data.total;
            // this.findStatus(data.data)
          })
          .finally(() => (this.tableLoading = false));
      },
      fetchSpecificVoice(page) {
        this.tableLoading = true;
        store
          .dispatch("reporting/fetchVoiceHistory", {
            id: this.$route.params.id,
            page: page,
          })
          .then((data) => {
            this.campaignDetails = data.data;
            this.total = data.total;
            // this.findStatus(data.data)
          })
          .finally(() => (this.tableLoading = false));
      },

      dataPointSelection(chartContext) {
        this.filterOn = true;
        this.statusSelectedIndex =
          this.campaignLabels[chartContext.dataPointIndex];
        if (this.statusSelectedIndex === "ACCEPTED") {
          this.statusSelectedIndex = "FAILED";
        }
        this.page = 1;
        this.prepareCampaignDetails();
      },
      exportReport() {
        this.exportLoading = true;
        if (loginType() === "sms") {
          store
            .dispatch("reporting/eportCampaignDetails", this.$route.params.id)
            .then((data) => {
              downloadcsv(
                data,
                `Campaign Details_${this.$route.params.id}_Individual_SMS`
              );
            })
            .finally(() => (this.exportLoading = false));
        } else {
          store
            .dispatch(
              "reporting/exportCampaignHistoryVoice",
              this.$route.params.id
            )
            .then((data) => {
              downloadcsv(
                data,
                `Campaign Details_${this.$route.params.id}_Individual_Voice`
              );
            })
            .finally(() => (this.exportLoading = false));
        }
      },
      findStatus(data) {
        const statusObject = {};
        data.forEach((obj) => {
          if (obj.status == "FAILED") {
            obj.status = "ACCEPTED";
          }
          statusObject[obj.status] = obj.total;
        });

        this.campaignSeries = Object.values(statusObject);
        this.campaignLabels = Object.keys(statusObject);
      },
      findVoiceStatus(data) {
        const statusObjet = {};
        data.forEach((obj) => {
          if (obj.dial_status == "not_dialed_yet") {
            obj.dial_status = "PENDING";
          }
          statusObjet[obj.dial_status] = obj.total;
        });
        this.campaignSeries = Object.values(statusObjet);
        this.campaignLabels = Object.keys(statusObjet);
      },
      refreshPageDisable() {
        this.$router.go(0);
        // disable button for 30 seconds with set timeout
      },
      checkRefund(scope) {
        if (scope.dial_status == "NO ANSWER") {
          if (scope.refund == "1") {
            return "REFUNDED";
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      searchUser: _.debounce(function () {
        if (this.searchTableData === "") {
          return this.prepareCampaignDetails();
        }

        if (this.searchTableData !== "") {
          this.tableLoading = true;

          let payload = {
            campaign_id: this.$route.params.id,
            phone: this.searchTableData,
            service_type: loginType() == "sms" ? "sms" : "voice",
          };
          store
            .dispatch("reporting/searchCampaignHistory", payload)
            .then((data) => {
              this.campaignDetails = data.data;
              this.total = data.data.length;
            })
            .catch(() => {
              this.$message({
                type: "error",
                message: "There was an issue searching for this contact",
              });
            })
            .finally(() => {
              this.tableLoading = false;
            });
        }
        // Hit your endpoint here using this.searchTerm as the search query.
      }, 700),
      prepareCampaignDetails() {
        this.tableLoading = true;
        if (loginType() == "sms") {
          store
            .dispatch("reporting/fetchSMSHistoryDetails", {
              id: this.$route.params.id,
              page: 1,
              status: this.statusSelectedIndex,
            })
            .then((data) => {
              this.findStatus(data.message_status);
              this.campaignDetails = data.data;
              this.total = data.total;
            })
            .finally(() => {
              this.tableLoading = false;
            });
        } else {
          store
            .dispatch("reporting/fetchVoiceHistory", {
              id: this.$route.params.id,
              page: 1,
            })
            .then((data) => {
              this.campaignDetails = data.data;
              this.findVoiceStatus(data.voice_status);
              this.total = data.total;
            })
            .finally(() => (this.tableLoading = false));
        }
      },
    },
    computed: {
      displayData() {
        // this.total = this.searching.length;
        return this.campaignDetails;
      },
      logintype() {
        return loginType();
      },
      isProcessing() {
        return (
          (this.campaignDetails.length == 0 &&
            this.processingFlag &&
            this.$t("dashboard.view_campaign.currently_processing")) ||
          "No data found"
        );
      },
    },
    beforeMount() {
      this.loading = true;
      if (loginType() == "sms") {
        store
          .dispatch("reporting/fetchSMSHistoryDetails", {
            id: this.$route.params.id,
            page: 1,
          })
          .then((data) => {
            this.findStatus(data.message_status);
            this.campaignDetails = data.data;
            this.total = data.total;
            if (this.campaignDetails.length == 0) {
              this.processingFlag = true;
            }
          })
          .finally(() => {
            this.loading = false;
            if (localStorage.getItem("askedForReview") == "true") {
              store.dispatch("modal/showFeedbackModal", true);
            }
            if (sessionStorage.getItem("hasFilledCategory") == null) {
              store.dispatch("modal/showCategoriesModal", true);
            }
          });
      } else {
        store
          .dispatch("reporting/fetchVoiceHistory", {
            id: this.$route.params.id,
            page: 1,
          })
          .then((data) => {
            this.campaignDetails = data.data;
            this.findVoiceStatus(data.voice_status);
            this.total = data.total;
          })
          .finally(() => (this.loading = false));
      }
    },
    created() {
      setTimeout(() => {
        this.buttonDisabled = false;
      }, 30000);
    },
  };
</script>

<style scoped>
  @import url("../../../styles/__contacts.scss");

  .contact_table {
    width: 100%;
  }

  .reporting {
    height: 782px;
  }

  .__reporting__table {
    height: 732px;
  }

  .__back__btn {
    margin-right: 10%;
    outline: none;
    background-color: transparent;
    border: none;
  }

  .__details {
    border-top: 1px solid #f3f3f4;
    margin-right: 1em;
    margin-left: 1em;
    display: flex;
    justify-content: space-between;
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .credit_used {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    color: #484a4f;
    margin: 0px;
  }

  .c_total {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    color: #484a4f;
  }

  .export {
    width: 92px;
    height: 38px;
    background: #f7921c;
    border-radius: 5px;
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
    outline: none;
    border: none;
  }

  .__headar {
    padding-left: 1em;
  }

  .scrollable-report {
    overflow-y: auto;
  }

  .contains-link-back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  .is-view-campaign {
    width: 13ch;
  }

  .refresh-button {
    display: flex;
    width: 90%;
    justify-content: flex-end;
    padding-right: 1rem;
  }

  .form_contain {
    display: flex;
    width: 15rem;
    justify-content: flex-end;
    padding-right: 3rem;
    align-items: center;
  }

  .scrollable {
    height: 79vh;
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    .__details {
      flex-direction: column;
      align-items: center;
    }

    .__back__btn {
      /* margin-bottom: 1em; */
    }

    .export {
      margin-bottom: 1em;
    }

    .scrollable {
      height: 80vh;
      overflow-y: scroll;
      margin: 0 !important;
      padding: 0 !important;
    }

    .scrollable::-webkit-scrollbar {
      display: none;
    }

    *::-webkit-scrollbar {
      display: none;
    }

    .scrollable-report {
      height: auto;
      overflow: hidden;
    }

    .scrollable-report * {
      height: auto;
      overflow: hidden;
    }

    .contact_table {
      height: auto;
      overflow: hidden;
    }
  }

  .refresh-button-main:disabled {
    background-color: gainsboro;
    border-color: gainsboro;
  }
</style>
